import React from "react";

import { Center, Image } from "@chakra-ui/react";

import { ImageId } from "../types";

import ReviewImage from "../images/Review";
import ErrorFile from "../images/error-file.svg";
import ExpertAssistConfirm from "../images/expert_assist_confirm_illustration.png";
import ExpertAssistHelpChart from "../images/expert_assist_help_chart.png";
import ExpertAssistHero from "../images/expert_assist_hero_detail_screen.png";
import ExpertAssistProfiles from "../images/expert_assist_profiles.png";
import ExpertAssistSuccess from "../images/expert_assist_success_illustration.png";
import ImportPriorReturn from "../images/import_prior_return.png";
import MilestoneBeforeCredits from "../images/milestone-before-credits.svg";
import MilestoneBeforeFiling from "../images/milestone-before-filing.svg";
import MilestoneBeforeIncome from "../images/milestone-before-income.svg";
import MilestoneBeforeState from "../images/milestone-before-state.svg";
import MilestoneInitial from "../images/milestone-initial.svg";
import PartnerPrefillNotice from "../images/partner-prefill-notice.svg";
import PyAGIImageFrom2022 from "../images/py-agi-from-2022.png";
import PyAGIImage from "../images/py-agi.png";
import SparkleFile from "../images/sparkle-file.svg";
import SuccessCheckmark from "../images/success-checkmark.svg";

interface Props {
  imageId: string;
}

const ImageBlock: React.FC<Props> = ({ imageId }) => {
  let image;

  switch (imageId) {
    case ImageId.PY_AGI:
      // Image of a 1040 that has 2021 in the header
      image = <img src={PyAGIImage} />;
      break;
    case ImageId.PY_AGI_FROM_2022:
      // Image of a 1040 that has 2022 in the header
      image = <img src={PyAGIImageFrom2022} />;
      break;
    case ImageId.REVIEW:
      image = <ReviewImage />;
      break;
    case ImageId.SPARKLE_FILE:
      image = <Image src={SparkleFile} />;
      break;
    case ImageId.ERROR_FILE:
      image = <Image src={ErrorFile} />;
      break;
    case ImageId.SUCCESS_CHECKMARK:
      image = <Image src={SuccessCheckmark} />;
      break;
    case ImageId.MILESTONE_INITIAL:
      image = <Image minH="171px" src={MilestoneInitial} />;
      break;
    case ImageId.MILESTONE_BEFORE_INCOME:
      image = <Image minH="85px" src={MilestoneBeforeIncome} />;
      break;
    case ImageId.MILESTONE_BEFORE_CREDITS:
      image = <Image minH="85px" src={MilestoneBeforeCredits} />;
      break;
    case ImageId.MILESTONE_BEFORE_STATE:
      image = <Image minH="85px" src={MilestoneBeforeState} />;
      break;
    case ImageId.MILESTONE_BEFORE_FILING:
      image = <Image minH="85px" src={MilestoneBeforeFiling} />;
      break;
    case ImageId.IMPORT_PRIOR_RETURN:
      image = (
        <Image
          minH="85px"
          width="100px"
          height="94px"
          src={ImportPriorReturn}
        />
      );
      break;
    case ImageId.PARTNER_PREFILL_NOTICE:
      image = <Image minH="85px" src={PartnerPrefillNotice} />;
      break;
    case ImageId.EXPERT_ASSIST_PROFILES:
      image = <Image src={ExpertAssistProfiles} maxH="52px" width="auto" />;
      break;
    case ImageId.EXPERT_ASSIST_HERO:
      image = <Image src={ExpertAssistHero} maxH="280PX" width="auto" />;
      break;
    case ImageId.EXPERT_ASSIST_CONFIRM:
      image = <Image src={ExpertAssistConfirm} maxH="94px" width="auto" />;
      break;
    case ImageId.EXPERT_ASSIST_HELP_CHART:
      image = <Image src={ExpertAssistHelpChart} maxH="288px" width="auto" />;
      break;
    case ImageId.EXPERT_ASSIST_SUCCESS:
      image = <Image src={ExpertAssistSuccess} maxH="112px" width="auto" />;
      break;
  }

  return <Center>{image}</Center>;
};

export default ImageBlock;
