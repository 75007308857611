import React, { useCallback, useEffect, useRef } from "react";

import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Flex,
  useTheme,
} from "@chakra-ui/react";

import { AlertBlockNavigateAction, AlertTypes, Resource } from "../types";

import rollbar from "../../rollbar-utils";
import { renderWithHtml } from "../helpers/TextFormatters";
import ErrorIcon from "../icons/ErrorIcon";
import HelpIcon from "../icons/HelpIcon";
import InfoIcon from "../icons/InfoIcon";
import SparkleFillIcon from "../icons/SparkleFillIcon";
import WarningIcon from "../icons/WarningIcon";

interface Props {
  title?: string;
  text: string;
  status: AlertTypes;
  onSubmit?: (screenId: string, resource?: Resource) => void;
  resource?: Resource;
  navigateAction?: AlertBlockNavigateAction;
}

const AlertBlock: React.FC<Props> = ({
  title,
  text,
  status,
  onSubmit,
  resource,
  navigateAction,
}) => {
  let borderColor = "brand";
  let backgroundColor = "brand";
  let statusIcon;

  const theme = useTheme();
  const contentRef = useRef<HTMLDivElement>(null);

  const handleClick = useCallback(() => {
    if (navigateAction && onSubmit) {
      onSubmit(navigateAction.screenId, resource);
    }
  }, [navigateAction, onSubmit]);

  // If an navigateAction is passed down, we know there is a navigation event to be handled
  // We add a click event listener to the clickable element and navigate to the screen provided
  useEffect(() => {
    if (contentRef.current && navigateAction) {
      const clickableElement = contentRef.current.querySelector(
        navigateAction.selector,
      ) as HTMLElement;
      if (clickableElement) {
        clickableElement.style.cursor = "pointer";
        clickableElement.style.fontWeight = "600";
        clickableElement.addEventListener("click", handleClick);
        return () => clickableElement.removeEventListener("click", handleClick);
      } else {
        rollbar.warn(
          `No clickable element (${clickableElement}) found for the navigateAction (${navigateAction})`,
        );
      }
    } else {
      rollbar.warn(
        `No navigateAction (${navigateAction}) found for the contentRef.`,
      );
    }
  }, [navigateAction, handleClick]);

  switch (status) {
    case AlertTypes.WARNING:
      borderColor = "warning.dark";
      backgroundColor = "warning.light";
      statusIcon = <WarningIcon />;
      break;
    case AlertTypes.ERROR:
      borderColor = "error.dark";
      backgroundColor = "error.light";
      statusIcon = <ErrorIcon />;
      break;
    case AlertTypes.INFO:
      borderColor = "brand.medium";
      backgroundColor = "brand.light";
      statusIcon = <InfoIcon color={theme.colors.brand.medium} />;
      break;
    case AlertTypes.PREFILL:
      borderColor = "prefill.medium";
      backgroundColor = "prefill.light";
      statusIcon = <SparkleFillIcon color={theme.colors.prefill.medium} />;
      break;
    case AlertTypes.HELP:
      borderColor = "brand.medium";
      backgroundColor = "brand.light";
      statusIcon = <HelpIcon color={theme.colors.brand.medium} />;
      break;
    case AlertTypes.SUBTLE_INFO:
    default:
      borderColor = "border.medium";
      backgroundColor = "background.dark";
      statusIcon = <InfoIcon color="var(--chakra-colors-text-secondary)" />;
      break;
  }

  return (
    <Alert
      borderColor={borderColor}
      bgColor={backgroundColor}
      borderWidth="1px"
      borderRadius="12px"
      px={3}
      py={4}
    >
      <Flex direction="row" alignItems="flex-start">
        <Box>{statusIcon}</Box>
        <Box flexDirection="column" ml={3}>
          {title && <AlertTitle>{title}</AlertTitle>}
          <AlertDescription
            fontSize="md"
            whiteSpace="pre-line"
            ref={contentRef}
          >
            {renderWithHtml(text)}
          </AlertDescription>
        </Box>
      </Flex>
    </Alert>
  );
};

export default AlertBlock;
