import React, { useContext } from "react";

import { InfoOutlineIcon } from "@chakra-ui/icons";
import { Box, Flex, Heading, Link, Text } from "@chakra-ui/react";

import { ContentListType, OpenHelpTypes } from "../types";

import { FeatureFlagContext } from "../../App";
import { openZendesk } from "../../zendesk";
import ContentListRenderer from "./ContentListRenderer";
import Modal from "./Modal";

function HelpContent({
  title,
  helpContentBlocks = [],
  onClose,
}: {
  title: string;
  helpContentBlocks?: ContentListType;
  onClose?: () => void;
}) {
  const featureFlags = useContext(FeatureFlagContext);
  const useZendeskSandbox = !!(featureFlags && featureFlags.useZendeskSandbox);
  const useZendeskProductionMessaging = !!(
    featureFlags && featureFlags.useZendeskProductionMessaging
  );

  const useZendeskProductionClassic = !(
    useZendeskSandbox || useZendeskProductionMessaging
  );
  return (
    <Flex flexDirection="column" gap="4">
      <Heading
        pt={{ base: 4, md: 0 }}
        size="lg"
        pr={8}
        dangerouslySetInnerHTML={{ __html: title }}
      ></Heading>
      <Flex justifyContent="flex-start" gap={4} flexDir="column">
        <ContentListRenderer contentList={helpContentBlocks} />
      </Flex>

      <Text mb={{ base: 8, md: 0 }}>
        {"Need more help? "}
        <Link
          color="brand.medium"
          fontWeight="medium"
          onClick={() => {
            openZendesk(useZendeskProductionClassic);
            if (onClose) {
              onClose();
            }
          }}
        >
          Visit our help center
        </Link>
      </Text>
    </Flex>
  );
}

function HelpModal({
  openEventProperties,
  openText,
  helpContentBlocks,
  icon,
}: {
  openEventProperties: {
    openKey: string;
    openType: OpenHelpTypes;
    screenId: string;
  };
  openText: string;
  icon?: JSX.Element;
  helpContentBlocks?: ContentListType;
}) {
  return (
    <Box>
      <Modal
        openEventProperties={openEventProperties}
        openText={openText}
        icon={
          icon || (
            <InfoOutlineIcon
              color="brand.medium"
              mr="2"
              fontWeight="medium"
              width="3"
              height="3"
            />
          )
        }
        body={
          <HelpContent title={openText} helpContentBlocks={helpContentBlocks} />
        }
      ></Modal>
    </Box>
  );
}

export default HelpModal;
