import React from "react";

import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";

import { sendEventButtonClick } from "../api";
import { BlockType, IconId, ScreenId, Status } from "../types";

import { openLink } from "../../fileDownloadUtils";
import { convertToExternalBrowserUrl } from "../../utils/nav-utils";
import { openZendesk } from "../../zendesk";
import CallExpertModalBody from "./CallExpertModalBody";
import NavigationChoice from "./NavigationChoice";

interface Props {
  screenId: string;
}

const ExpertAssistLandingBlock: React.FC<Props> = ({ screenId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const callExpertClick = () => {
    sendEventButtonClick({
      buttonName: "ExpertAssistCallNow",
      screenId: screenId,
    });
    onOpen();
  };

  const scheduleCallClick = () => {
    sendEventButtonClick({
      buttonName: "ExpertAssistSchedule",
      screenId: screenId,
    });
    const calendly = "https://calendly.com/lori-30min/taxpayer-support-session";
    openLink(convertToExternalBrowserUrl(calendly));
  };

  const chatWithBotClick = () => {
    sendEventButtonClick({
      buttonName: "ExpertAssistChat",
      screenId: screenId,
    });
    openZendesk(false);
  };

  const assistOptions = [
    // STOPLAUNCH(marcia): Update these three icons to match figma:
    // https://www.figma.com/design/zBFDlq79xtJtBjq03qigWk/DIWM?node-id=775-8088&node-type=CANVAS&t=IckrkpVC02fFW3uH-0
    {
      label: "Call an expert now",
      subLabel:
        "We’re available to take your call Monday - Friday, 10am - 5pm ET.",
      iconId: IconId.PERSON,
      buttonLabel: "Call Now",
      onClick: callExpertClick,
    },
    {
      label: "Schedule a call for later",
      subLabel:
        "Schedule a call with one of our experts at a time that works best for you. ",
      iconId: IconId.LOCATION,
      buttonLabel: "Schedule Call",
      onClick: scheduleCallClick,
    },
    {
      label: "Chat with our bot",
      subLabel:
        "See if our chatbot can answer your question. The bot’s an expert too, and always available.",
      iconId: IconId.REVIEW_AND_FILE,
      buttonLabel: "Start Chat",
      onClick: chatWithBotClick,
    },
  ];
  return (
    <Box display="flex" gap={6} flexDirection="column" width="full">
      {/* This is copied from NavigationScreen which applies this parent gap */}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        motionPreset="slideInBottom"
      >
        <ModalOverlay />

        <ModalContent
          maxH="calc(100% - 24px)" // 24px boundary from https://linear.app/column-tax/issue/COL-6060#comment-91353658
        >
          <ModalCloseButton />
          <ModalBody p={{ base: 6, md: 0 }}>
            <Flex
              direction="column"
              height="full"
              justifyContent="space-between"
            >
              <CallExpertModalBody />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      {assistOptions.map((option) => {
        // STOPLAUNCH(marcia): Decide on a key that will be more stable than the text label
        const key = option.label;
        return (
          // STOPLAUNCH(marcia): This is a lot of re-purposing of our navigation choice code that
          // I do not fully understand yet! I think we could deprecate some of these fields.
          // STOPLAUNCH(marcia): Hnadle the loading spinner when these are clicked

          // STOPLANCH(marcia): Wire these clicks up to do the real thing
          // See https://linear.app/column-tax/issue/COL-24194/connect-click-targets-on-expert-assist-screen-to-their-destinations

          <NavigationChoice
            key={option.label}
            navigationDisabled={false}
            showLoadingSpinner={false}
            choice={{
              id: key,
              iconId: option.iconId,
              label: option.label,
              subLabel: option.subLabel,
              status: {
                label: option.buttonLabel,
                status: Status.CUSTOM_EXPERT_ASSIST,
                color: "unused",
                backgroundColor: "unused",
              },
              type: BlockType.NAVIGATION_CHOICE,
              screenId: ScreenId.UNUSED,
              isEnabled: true,
              info: [],
              recommended: true,
            }}
            onClick={option.onClick}
          />
        );
      })}
    </Box>
  );
};

export default ExpertAssistLandingBlock;
