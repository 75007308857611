import React from "react";

import { Box, Button, Flex, Text } from "@chakra-ui/react";

import { sendEventButtonClick } from "../api";
import { ImageId, Resource } from "../types";

import ImageBlock from "./ImageBlock";

interface Props {
  onSubmit: (screenId: string, resource?: Resource) => void;
  screenId: string;
}

const ExpertAssistEntrypointBlock: React.FC<Props> = ({
  onSubmit,
  screenId,
}) => {
  const handleClick = () => {
    // STOPLAUNCH(nihar): This screen id should come from the BE, and we should handle it however we handle the same navigation in SupportHeader.tsx
    // Perhaps it reads from the same place.
    const hardcodedScreenId = "TY23::Screens::ExpertAssistScreen";
    sendEventButtonClick({
      buttonName: "ExpertAssistEntrypointBlock",
      screenId: screenId,
    });
    onSubmit(hardcodedScreenId);
  };

  return (
    <Box background="white" rounded="card" boxShadow="card" width="100%">
      <Flex
        alignItems="center"
        background="brand.light"
        px={{ base: 4, navmenu: 8 }}
        py={{ base: 3, navmenu: 4 }}
        width="100%"
        gap={4}
        roundedTop="card"
      >
        <ImageBlock imageId={ImageId.EXPERT_ASSIST_PROFILES} />
        <Text fontWeight="semibold">You’re enrolled in Expert Assist</Text>
      </Flex>
      <Flex
        px={{ base: 4, navmenu: 8 }}
        py={{ base: 3, navmenu: 4 }}
        roundedBottom="card"
        flexDir={{ base: "column", navmenu: "row" }}
        width="100%"
        gap={3}
        justifyContent={{ base: "center", navmenu: "space-between" }}
        alignItems={{ base: "flex-start", navmenu: "center" }}
      >
        <Text>Get live help now or schedule a call with an expert.</Text>
        <Button
          variant="outline"
          size="md"
          onClick={handleClick}
          width={{ base: "100%", navmenu: "auto" }}
        >
          Talk to an Expert
        </Button>
      </Flex>
    </Box>
  );
};

export default ExpertAssistEntrypointBlock;
