import {
  ContentRestriction,
  Disqualification,
  RequiredField,
  SDKEvent,
  Step,
  StepType,
  UserHistoryResult,
} from "../types";
import {
  apiUrl,
  clearAdminAuth,
  clearLocalStorage,
  getAdminAuth,
  isInternalUse,
  isUsingPullRequestBackend,
  readFromLocalStorage,
  saveAdminAuth,
  supportApiUrl,
} from "./utils";

import {
  BatchError,
  BatchRowValidationParams,
} from "../diy/internal/catalog/validations";
import HistoryUtils from "../diy/internal/history-utils";
import {
  AdminSubmissionItem,
  ErrorHandler,
  HostBankDashboardSummary,
  JurisdictionInformation,
  LocalStorageType,
  ManualReviewCount,
  ManualReviewSubmissionItem,
  NotReviewableEmailTemplate,
  ReviewOptions,
  ReviewQueueObject,
  SubmissionDashboardFilter,
  SubmissionDashboardSummary,
  SubmissionReviewSummary,
  SubmissionRuleItem,
  SubmissionState,
  TaxYearInformation,
  TestUser,
  UserReview,
  XmlTemplate,
} from "../diy/internal/types";
import { Jurisdiction, UserEvent } from "../diy/types";
import rollbar from "../rollbar-utils";
import { ApiError, BackendOfflineError, LoggedOutError } from "./errors";
import { delay } from "./general";
import { BASE_HEADERS, FORM_DATA_HEADERS } from "./headers";
import { getUserTokenIfExists } from "./params-utils";

const MAX_RETRIES = 3;

export const KLOVER_DEEP_LINK_URL = "joinklover://column-tax";

export type AdminFetchOptions = {
  isNotJson?: boolean;
  noMangle?: boolean;
};

export async function wrappedAdminFetch(
  url: RequestInfo,
  init?: RequestInit | FormData,
  options?: AdminFetchOptions,
  attempt?: number,
): Promise<Response> {
  const adminAuthHeaders = getAdminAuth();

  const headers: Record<string, string> = {
    ...BASE_HEADERS,
    ...adminAuthHeaders,
  };
  if (options?.isNotJson) {
    delete headers["Content-Type"];
  }

  if (options?.noMangle) {
    delete headers["Key-Inflection"];
  }

  const response = await fetch(url, {
    headers: headers,
    method: "GET",
    ...init,
  });

  if (response.status === 401) {
    // we retry because devise-token-auth by default requires a new access-token for each request except for
    // requests that happen within a configurable batch request buffer throttle (5 seconds by default) see
    // https://devise-token-auth.gitbook.io/devise-token-auth/conceptual for more information. We can inadvertantly
    // clear admin auth if multiple requests are fired off by the browser with a given token and then one or more of
    // the requests are validated after the time of (token creation + buffer throttle time out). If that happens
    // a 401 is returned and the token is cleared from the browser cookie storage and user is forced to re-login.
    // There is one exception to this, if one of the requests fired off by the browser returns a new token and happens
    // to complete after the request that received the 401, then a fresh token will be placed into the cookie store.
    //
    // Here is what is happening for a server that can process 2 requests simultaneously
    //
    // ---------------------------------------------------------------------------------------------------------------------
    // |    Time   | Token | Client Request | Duration  | Validated at time on server | Validation result | Returned token |
    // | 0 seconds |   T1  |       R1       | 7 seconds |         0 seconds           |         OK        |      nil       |
    // |           |   T1  |       R2       | 1 seconds |         0 seconds           |         OK        |      nil       |
    // |           |   T1  |       R3       | 8 seconds |         1 seconds           |         OK        |      T2        |
    // |           |   T1  |       R4       | 2 seconds |         7 seconds           |         401       |      N/A       |
    // | 5 seconds |       |                |           |                             |                   |                |
    // | 7 seconds |   T2  |  Retry of R4   | 2 seconds |         7 seconds           |         OK        |      nil       |
    // ---------------------------------------------------------------------------------------------------------------------
    //
    // In the table above R1, R2, R3, and R4 all start at the client at the same time, but due to the fact that the server
    // can only process two requests at the same time, R3 and R4 will be queued up waiting to be processed. By the time R3
    // is processed it will stil be within the 5 seconds buffer, but R4 won't be processed until 7 seconds, which is after
    // and will result in a 401. Our app will then interpret that as a need to re-login. However if we retry we can retry
    // with the latest token and see if it succeeds (at 7 seconds in the table).
    //
    // There are a few potential solutions
    // 1) serialize all requests to rails - could result in slowness in the browser
    // 2) extend batch request buffer throttle time, it might lessen the chance of this happening if each batch of browser
    //    interactions tend to complete within the window, but not clear this happens
    // 3) retry the request with a fresh token - not guranteed as a request can be retried and be unlucky each time if
    //    there are many requests going on
    //
    // (3) was chosen as it seemed to have least likely to experience the problem observed without slowing down
    if (attempt === undefined) {
      attempt = 0;
    }
    if (attempt < MAX_RETRIES) {
      return wrappedAdminFetch(url, init, options, attempt + 1);
    } else {
      warnAdminAuth(url);

      clearAdminAuth();
    }
  } else if (response.status >= 500) {
    // Server error or the server is down; throw an exception
    throw new Error("Server error");
  } else {
    // Save new auth headers to cookie, which change on each request
    saveAdminAuth(response.headers);
  }
  return response;
}

export async function signInAdminViaGoogle(body: string) {
  const response = await wrappedAdminFetch(
    `${apiUrl()}/internal/admin/validate_token`,
    {
      method: "POST",
      body,
    },
  );

  try {
    const json = await response.json();
    return { success: response.status === 200, email: json.email };
  } catch (e: unknown) {
    if (e instanceof SyntaxError) {
      return {
        success: response.status === 200,
        email: "mainnotmerged@columntax.com",
      };
    } else {
      return { success: false, email: "someothererror@columntax.com" };
    }
  }
}

export async function getJurisdictions(): Promise<
  Array<JurisdictionInformation>
> {
  const response = await wrappedAdminFetch(
    `${apiUrl()}/internal/admin/jurisdictions`,
  );

  if (!response.ok) {
    throw new Error();
  }

  return (await response.json()).jurisdictions;
}

export async function getTaxYears(): Promise<Array<TaxYearInformation>> {
  const response = await wrappedAdminFetch(
    `${apiUrl()}/internal/admin/tax_years`,
  );

  if (!response.ok) {
    throw new Error();
  }

  return (await response.json()).taxYears;
}

export async function getCatalogueUnitTests(
  namespace?: string,
  jurisdiction?: string,
) {
  const params = new URLSearchParams();
  if (namespace) {
    params.set("namespace", namespace);
  }
  if (jurisdiction) {
    params.set("jurisdiction", jurisdiction);
  }

  const response = await wrappedAdminFetch(
    `${apiUrl()}/internal/admin/catalogue?${params.toString()}`,
  );

  if (!response.ok) {
    throw new Error();
  }

  return await response.json();
}

export async function loggedIn(): Promise<boolean> {
  let response;

  try {
    response = await wrappedAdminFetch(`${apiUrl()}/internal/admin/logged_in`);
  } catch (e) {
    // If the review app is offline, then the fetch itself will fail and raise
    // a CORS error. We'll throw a BackendOfflineError so that the AdminLoginPage
    // can poll to wait for the review app to be ready
    throw new BackendOfflineError();
  }

  // The BE is online and responds with a 200 OK or not
  if (!response?.ok) {
    // The tax analyst/engineer must log in
    throw new LoggedOutError();
  }

  return true;
}

enum AdminPostEndpoints {
  CHECK_RUBY_CODE = "check_ruby_code",
  CHECKOUT_BRANCH = "checkout_branch",
  CLEAN_ULTRA_TAX_XML = "clean_ultra_tax_xml",
  SCENARIOS = "scenarios",
  SEARCH_SCENARIOS = "search_scenarios",
  SCENARIO_JSON = "scenario_json",
  UPDATE_SCENARIO = "update_scenario",
  RENAME_OR_DUPLICATE_SCENARIO = "rename_or_duplicate_scenario",
  INPUTS_TEMPLATE = "inputs_template",
  PREVIEW = "preview",
  PREVIEW_FORMULA = "preview_formula",
  RUN_UNIT_TESTS = "run_unit_tests",
  SAVE_FULL_RETURN_TEST = "save_full_return_test",
  SAVE_UNIT_TESTS = "save_unit_tests",
  XSD_TEMPLATES = "xsd_templates",
  XSD_TYPE_NAME_FOR_PATH = "xsd_type_for_path",
  BATCH_ROW_VALIDATION = "batch_row_validation",
  SUBMIT_A2A = "submit_a2a",
  SUBMIT_FTB = "submit_ftb",
  SUBMIT_FYT = "submit_fyt",
  FETCH_FYT_XML = "fetch_fyt_xml",
  SUBMISSION_DASHBOARD_FYT_CALC = "fyt_calc",
  PREVIEW_PDF = "preview_pdf",
  GET_FYT_PDF = "fyt_pdf",
  CREATE_PULL_REQUEST = "create_pull_request",
  MAP_PDF = "map_pdf",
}

type AdminPostOptions =
  | {
      endpoint: AdminPostEndpoints.CHECK_RUBY_CODE;
      body: {
        code: string;
      };
    }
  | {
      endpoint: AdminPostEndpoints.CHECKOUT_BRANCH;
      body: {
        branchName: string;
      };
    }
  | {
      endpoint: AdminPostEndpoints.RUN_UNIT_TESTS;
      body: { tests: string };
    }
  | {
      endpoint: AdminPostEndpoints.SAVE_UNIT_TESTS;
      body: { tests: string };
    }
  | {
      endpoint: AdminPostEndpoints.SAVE_FULL_RETURN_TEST;
      body: {
        name: string;
        inputs: string;
      };
    }
  | {
      endpoint: AdminPostEndpoints.PREVIEW;
      body?: {
        inputs: string;
      };
    }
  | {
      endpoint: AdminPostEndpoints.PREVIEW_FORMULA;
      body?: {
        inputs: string;
        formula: string;
        namespaceId: string;
      };
    }
  | {
      endpoint: AdminPostEndpoints.CLEAN_ULTRA_TAX_XML;
      body: { xml: string };
    }
  | {
      endpoint: AdminPostEndpoints.SCENARIOS;
      body: {
        jurisdiction: string;
      };
    }
  | {
      endpoint: AdminPostEndpoints.SEARCH_SCENARIOS;
      body: SearchScenariosRequest;
    }
  | {
      endpoint: AdminPostEndpoints.SCENARIO_JSON;
      body: {
        jurisdiction: string;
        name: string;
      };
    }
  | {
      endpoint: AdminPostEndpoints.UPDATE_SCENARIO;
      body: UpdateScenarioRequest;
    }
  | {
      endpoint: AdminPostEndpoints.RENAME_OR_DUPLICATE_SCENARIO;
      body: RenameOrDuplicateScenarioRequest;
    }
  | {
      endpoint: AdminPostEndpoints.INPUTS_TEMPLATE;
      body: null;
    }
  | {
      endpoint: AdminPostEndpoints.XSD_TEMPLATES;
      body: {
        path: string;
      };
    }
  | {
      endpoint: AdminPostEndpoints.XSD_TYPE_NAME_FOR_PATH;
      body: {
        path: string;
      };
    }
  | {
      endpoint: AdminPostEndpoints.BATCH_ROW_VALIDATION;
      body: BatchRowValidationParams;
    }
  | {
      endpoint: AdminPostEndpoints.SUBMIT_A2A;
      body?: {
        inputs: string;
      };
    }
  | {
      endpoint: AdminPostEndpoints.SUBMIT_FTB;
      body?: {
        inputs: string;
      };
    }
  | {
      endpoint: AdminPostEndpoints.SUBMIT_FYT;
      body?: {
        inputs: string;
      };
    }
  | {
      endpoint: AdminPostEndpoints.SUBMISSION_DASHBOARD_FYT_CALC;
      body: {
        return_id: string;
      };
    }
  | {
      endpoint: AdminPostEndpoints.FETCH_FYT_XML;
      body: FetchFYTXMLParams;
    }
  | {
      endpoint: AdminPostEndpoints.PREVIEW_PDF;
      body?: {
        inputs: string;
      };
    }
  | {
      endpoint: AdminPostEndpoints.MAP_PDF;
      body?: {
        pdf: string;
      };
    }
  | {
      endpoint: AdminPostEndpoints.CREATE_PULL_REQUEST;
      body: {
        dependentBranch: string;
        dependentPR: number;
      };
    };

async function postAdmin(options: AdminPostOptions, noMangle?: boolean) {
  const response = await wrappedAdminFetch(
    `${apiUrl()}/internal/admin/${options.endpoint}`,
    {
      method: "POST",
      body: JSON.stringify(options.body),
    },
    { noMangle: noMangle },
  );

  if (!response.ok) {
    const responseJson = await response.json();
    throw new ApiError(responseJson.error, responseJson.backtrace);
  }

  return await response.json();
}

export function runUnitTests(body: { tests: string }) {
  const parsedTestConfig = JSON.parse(body.tests);

  HistoryUtils.saveNewItem({
    type: LocalStorageType.UNIT_TEST,
    namespace: parsedTestConfig.class_name,
    tests: body.tests,
    date: Date.now(),
  });

  return postAdmin({
    endpoint: AdminPostEndpoints.RUN_UNIT_TESTS,
    body,
  });
}

export function createPullRequest(body: {
  dependentBranch: string;
  dependentPR: number;
  shouldCreatePR: boolean;
  description?: string;
}): Promise<{
  pullRequestUrl: string;
}> {
  return postAdmin({
    endpoint: AdminPostEndpoints.CREATE_PULL_REQUEST,
    body,
  });
}

export function previewComputationInputs(body?: {
  inputs: string;
  queryParams: string;
  jurisdiction: string;
}): Promise<{
  inputs: string;
  queryParams: string;
  xml: string;
  schemaValidationErrors: Array<string>;
  preview: { result: string; errors: string };
  businessRules: string;
  businessRulesErrors: Array<string>;
  disqualifications: Array<Disqualification>;
  eFileRequired: Array<RequiredField>;
  uiRequired: Array<RequiredField>;
  namespaces: Array<string>;
  inputRestrictions: Array<ContentRestriction>;
  submissionRules: string;
  computationErrors: Array<string>;
}> {
  if (body) {
    HistoryUtils.saveNewItem({
      type: LocalStorageType.COMPUTATION_INPUTS,
      date: Date.now(),
      inputs: body.inputs,
    });
  }

  return postAdmin({
    endpoint: AdminPostEndpoints.PREVIEW,
    body,
  });
}

async function refreshIfUnauthorized(response: Response) {
  if (!response.ok) {
    if (response.status === 401) {
      alert("You are not logged in. Close the alert to refresh the page");
      window.location.reload();
    } else {
      throw new ApiError((await response.json()).error);
    }
  }
}

export async function getHostBanks(): Promise<HostBankDashboardSummary> {
  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/host_banks`,
    {
      method: "GET",
    },
  );

  await refreshIfUnauthorized(response);
  return await response.json();
}

export interface CreateHostBankRequest {
  name: string;
}

export interface CreateHostBankResponse {
  clientId: string;
  clientSecret: string;
}

export async function createHostBank(
  createHostBankRequest: CreateHostBankRequest,
): Promise<CreateHostBankResponse> {
  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/host_banks`,
    {
      method: "POST",
      body: JSON.stringify({
        hostBank: createHostBankRequest,
      }),
    },
  );

  await refreshIfUnauthorized(response);
  return await response.json();
}

export async function updateHostBank(formData: FormData): Promise<void> {
  const hostBankId = formData.get("host_bank[id]");
  formData.delete("host_bank[id]");

  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/host_banks/${hostBankId}`,
    {
      method: "PUT",
      body: formData,
    },
    { isNotJson: true },
  );

  await refreshIfUnauthorized(response);
  return await response.json();
}

export async function regenerateAllPDFs(): Promise<void> {
  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/host_banks/regenerate_all_pdfs`,
    { method: "POST" },
  );

  await refreshIfUnauthorized(response);
  await response.json();
}

export type CreateWebhookApplicationResponse = {
  applicationId: string;
};

export async function createWebhookApplication(
  hostBankID: number,
): Promise<CreateWebhookApplicationResponse> {
  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/host_banks/${hostBankID}/webhook_application`,
    {
      method: "POST",
    },
  );

  await refreshIfUnauthorized(response);
  return await response.json();
}

export async function getSubmissionDashboard(
  params: SubmissionDashboardFilter,
): Promise<SubmissionDashboardSummary> {
  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/submission_dashboard/summary`,
    {
      method: "POST",
      body: JSON.stringify(params),
    },
  );

  refreshIfUnauthorized(response);
  return await response.json();
}

export async function getSubmissionRules(): Promise<SubmissionRuleItem[]> {
  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/submission_dashboard/submission_rules`,
  );

  refreshIfUnauthorized(response);
  return await response.json();
}

export async function getReviewQueue(
  jurisdiction: string,
): Promise<ReviewQueueObject[]> {
  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/submission_dashboard/review_queue?jurisdiction=${jurisdiction}`,
  );

  return await response.json();
}

export async function setReviewerForReturn(
  reviewer: string,
  submissionId: string,
): Promise<void> {
  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/manual_review_dashboard/update_reviewer`,
    {
      method: "POST",
      body: JSON.stringify({ reviewer: reviewer, submissionId: submissionId }),
    },
  );

  refreshIfUnauthorized(response);
}

export async function getFilterDropdownData(): Promise<{
  reviewers: { label: string; value: string }[];
  jurisdictions: string[];
}> {
  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/manual_review_dashboard/dropdown_filters`,
  );

  refreshIfUnauthorized(response);
  return await response.json();
}

export async function getJurisdictionCounts(): Promise<{
  data: ManualReviewCount[];
}> {
  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/manual_review_dashboard/jurisdiction_counts`,
  );

  refreshIfUnauthorized(response);
  return await response.json();
}

export async function setManualReviewTargetCounts(
  jurisdiction: string,
  targetCount: number,
): Promise<{
  success: boolean;
}> {
  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/manual_review_dashboard/set_target_count`,
    {
      method: "POST",
      body: JSON.stringify({
        jurisdiction: jurisdiction,
        targetCount: targetCount,
      }),
    },
  );

  refreshIfUnauthorized(response);
  return await response.json();
}

export async function getManualReviewSubmissionData(
  page: number,
  jurisdiction?: string,
  reviewer?: string,
): Promise<{ data: ManualReviewSubmissionItem[]; totalCount: number }> {
  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/manual_review_dashboard/dashboard?jurisdiction=${jurisdiction}&reviewer=${reviewer}&page=${page}`,
  );

  refreshIfUnauthorized(response);
  return await response.json();
}

export async function setManualReviewSubmissionReviewer(
  userId: string,
  reviewer: string,
): Promise<void> {
  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/manual_review_dashboard/update_reviewer`,
    {
      method: "POST",
      body: JSON.stringify({ userId, assignedReviewerEmail: reviewer }),
    },
  );

  refreshIfUnauthorized(response);
}

export async function getJurisdictionSummaries(
  userId: string,
  taxYear?: string,
): Promise<SubmissionReviewSummary> {
  let user_tax_year = "";
  if (taxYear) {
    user_tax_year = new URLSearchParams({
      tax_year_for_user_summary: taxYear,
    }).toString();
  }
  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/submission_dashboard/jurisdiction_summaries/${userId}?${user_tax_year}`,
  );

  if (!response.ok) {
    throw new ApiError((await response.json()).error);
  }

  const result = await response.json();

  const latestFytResponse = result.federalJurisdictionReviewSummary
    .latestFytResponseJson
    ? JSON.parse(result.federalJurisdictionReviewSummary.latestFytResponseJson)
    : undefined;

  const stateJurisdictionReviewSummaries =
    result.stateJurisdictionReviewSummaries.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (stateJurisdictionReviewSummary: any) => ({
        ...stateJurisdictionReviewSummary,
        computedReturnSummary: {
          ...stateJurisdictionReviewSummary.computedReturnSummary,
          businessRules: JSON.parse(
            stateJurisdictionReviewSummary.computedReturnSummary.businessRules,
          ),
          submissionRules: JSON.parse(
            stateJurisdictionReviewSummary.computedReturnSummary
              .submissionRules,
          ),
        },
        latestFytResponse,
      }),
    );

  return {
    ...result,
    stateJurisdictionReviewSummaries,
    federalJurisdictionReviewSummary: {
      ...result.federalJurisdictionReviewSummary,
      computedReturnSummary: {
        ...result.federalJurisdictionReviewSummary.computedReturnSummary,
        businessRules: JSON.parse(
          result.federalJurisdictionReviewSummary.computedReturnSummary
            .businessRules,
        ),
        submissionRules: JSON.parse(
          result.federalJurisdictionReviewSummary.computedReturnSummary
            .submissionRules,
        ),
      },
      latestFytResponse,
    },
  };
}

export async function getUserDetails(
  userId: string,
  taxYear?: string,
): Promise<UserReview> {
  let user_tax_year = "";
  if (taxYear) {
    user_tax_year = new URLSearchParams({
      tax_year_for_user_summary: taxYear,
    }).toString();
  }
  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/submission_dashboard/user/${userId}?${user_tax_year}`,
  );

  await refreshIfUnauthorized(response);
  return await response.json();
}

export async function updateReturnNotes(
  returnId: string,
  notes: string,
): Promise<{ notes: string }> {
  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/submission_dashboard/notes/${returnId}`,
    {
      method: "POST",
      body: JSON.stringify({
        notes: notes,
      }),
    },
  );

  await refreshIfUnauthorized(response);
  return await response.json();
}

export async function mockMarkReturnAccepted(
  returnId: string,
  type: Jurisdiction,
) {
  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/submission_dashboard/mock_accepted/${returnId}`,
    {
      method: "POST",
      body: JSON.stringify({
        jurisdiction: type,
      }),
    },
  );
  await refreshIfUnauthorized(response);

  return await response.json();
}

export async function mockMarkReturnAsRejected(
  returnId: string,
  type: Jurisdiction,
  rejectionRules?: {
    ruleIdentifier: string;
    index?: number;
  }[],
) {
  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/submission_dashboard/mock_rejected/${returnId}`,
    {
      method: "POST",
      body: JSON.stringify({
        jurisdiction: type,
        rejectionRules: rejectionRules,
      }),
    },
  );

  await refreshIfUnauthorized(response);
  return await response.json();
}

export async function getTestUsers(): Promise<{ testUsers: TestUser[] }> {
  const response = await wrappedAdminFetch(
    `${apiUrl()}/internal/admin/test_users`,
  );

  await refreshIfUnauthorized(response);
  return await response.json();
}

export async function getAllScreenIds(): Promise<{ screenIds: string[] }> {
  const response = await wrappedAdminFetch(
    `${apiUrl()}/internal/admin/all_screen_ids`,
  );

  await refreshIfUnauthorized(response);
  return await response.json();
}

export async function getErrorHandlers(
  jurisdiction: Jurisdiction,
): Promise<{ errorHandlers: ErrorHandler[] }> {
  const response = await wrappedAdminFetch(
    `${apiUrl()}/internal/admin/error_handlers?jurisdiction=${jurisdiction}`,
  );

  await refreshIfUnauthorized(response);
  return await response.json();
}

export async function getUrlForUser({
  userUuid,
  screenId,
}: {
  userUuid: string;
  screenId?: string;
}): Promise<{ diyUrl: string }> {
  const response = await wrappedAdminFetch(
    `${apiUrl()}/internal/admin/get_url_for_user`,
    {
      method: "POST",
      body: JSON.stringify({
        userUuid: userUuid,
        screenId: screenId,
      }),
    },
  );

  if (!response.ok) {
    throw new ApiError((await response.json()).error);
  }

  return await response.json();
}

export async function submitReturn(
  id: string,
  submitFYT: boolean,
  note?: string,
): Promise<void> {
  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/submission_dashboard/submit/${id}`,
    {
      method: "POST",
      body: JSON.stringify({
        note: note,
        submitFYT: submitFYT,
      }),
    },
  );

  await refreshIfUnauthorized(response);
}

export async function submitCAReturn(id: string, note?: string): Promise<void> {
  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/submission_dashboard/submit_ftb/${id}`,
    {
      method: "POST",
      body: JSON.stringify({
        note: note,
      }),
    },
  );

  await refreshIfUnauthorized(response);
}

export async function submitJurisdictionReturn(
  id: string,
  jurisdiction: Jurisdiction,
  note?: string,
): Promise<void> {
  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/submission_dashboard/submit_jurisdiction/${id}`,
    {
      method: "POST",
      body: JSON.stringify({
        jurisdiction: jurisdiction,
        note: note,
      }),
    },
  );

  await refreshIfUnauthorized(response);
}

export async function unlockReturn(
  id: string,
): Promise<{ submissionState: SubmissionState }> {
  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/submission_dashboard/unlock/${id}`,
    {
      method: "POST",
    },
  );

  await refreshIfUnauthorized(response);

  return await response.json();
}

export async function forceReviewable(
  userId: string,
  jurisdiction: Jurisdiction,
  taxYear: string,
): Promise<{ submissionState: SubmissionState }> {
  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/submission_dashboard/force_reviewable/${userId}`,
    {
      method: "POST",
      body: JSON.stringify({
        jurisdiction: jurisdiction,
        tax_year: taxYear,
      }),
    },
  );

  await refreshIfUnauthorized(response);

  return await response.json();
}

export async function notReviewable(
  id: string,
  template: NotReviewableEmailTemplate,
): Promise<{ submissionState: SubmissionState }> {
  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/submission_dashboard/mark_not_reviewable/${id}`,
    {
      method: "POST",
      body: JSON.stringify({
        template: template,
      }),
    },
  );

  await refreshIfUnauthorized(response);

  return await response.json();
}

export async function runRejectionHandlerForReturn(
  id: string,
): Promise<{ submissionState: SubmissionState }> {
  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/submission_dashboard/run_rejection_handler/${id}`,
    {
      method: "POST",
    },
  );

  await refreshIfUnauthorized(response);

  return await response.json();
}

export async function submitReview(
  id: string,
  reviewOption: ReviewOptions,
  notes: string,
  shouldSubmit: boolean,
) {
  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/submission_dashboard/ta-review/${id}`,
    {
      method: "POST",
      body: JSON.stringify({
        notes: notes,
        reviewOption: reviewOption,
        shouldSubmit: shouldSubmit,
      }),
    },
  );

  await refreshIfUnauthorized(response);

  return await response.json();
}

export async function syncSheet(id: string) {
  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/submission_dashboard/sync_sheet/${id}`,
    {
      method: "POST",
    },
  );

  await refreshIfUnauthorized(response);

  return await response.json();
}

export async function submitCAReview(
  id: string,
  reviewOption: ReviewOptions,
  notes: string,
  shouldSubmit: boolean,
) {
  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/submission_dashboard/ta-review-ca/${id}`,
    {
      method: "POST",
      body: JSON.stringify({
        notes: notes,
        reviewOption: reviewOption,
        shouldSubmit: shouldSubmit,
      }),
    },
  );

  await refreshIfUnauthorized(response);

  return await response.json();
}

export async function submitJurisdictionReview(
  id: string,
  reviewOption: ReviewOptions,
  jurisdiction: Jurisdiction,
  notes: string,
  shouldSubmit: boolean,
) {
  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/submission_dashboard/ta-review-jurisdiction/${id}`,
    {
      method: "POST",
      body: JSON.stringify({
        jurisdiction: jurisdiction,
        notes: notes,
        reviewOption: reviewOption,
        shouldSubmit: shouldSubmit,
      }),
    },
  );

  await refreshIfUnauthorized(response);

  return await response.json();
}

export type PdfData = {
  blob: Blob;
  filename: string;
};

export async function getPdfFile(
  userId: string,
  taxYear?: string,
): Promise<Response> {
  let user_tax_year = "";
  if (taxYear) {
    user_tax_year = new URLSearchParams({
      tax_year_for_user_summary: taxYear,
    }).toString();
  }
  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/submission_dashboard/pdf/${userId}?${user_tax_year}`,
  );

  await refreshIfUnauthorized(response);

  return response;
}

export async function getHistory(
  userId: string,
  taxYear?: string,
): Promise<UserHistoryResult> {
  let user_tax_year = "";
  if (taxYear) {
    user_tax_year = new URLSearchParams({
      tax_year_for_user_summary: taxYear,
    }).toString();
  }
  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/submission_dashboard/input_history/${userId}?${user_tax_year}`,
  );

  if (!response.ok) {
    throw new ApiError((await response.json()).error);
  }
  return response.json();
}

export function submitA2a(body?: {
  inputs: string;
  jurisdiction?: string;
}): Promise<{ response: string; xml: string }> {
  return postAdmin({
    endpoint: AdminPostEndpoints.SUBMIT_A2A,
    body,
  });
}

export function submitFTB(body?: {
  inputs: string;
}): Promise<{ messageId: string }> {
  return postAdmin({
    endpoint: AdminPostEndpoints.SUBMIT_FTB,
    body,
  });
}

export async function retrieveFTBSubmissions(): Promise<{
  ftbSubmissions: string[];
}> {
  const response = await wrappedAdminFetch(
    `${apiUrl()}/internal/admin/ftb_submissions`,
  );

  if (!response.ok) {
    throw new ApiError((await response.json()).error);
  }

  return await response.json();
}

export async function retrieveStatus(
  submissionId: string,
): Promise<{ status: string }> {
  const response = await wrappedAdminFetch(
    `${apiUrl()}/internal/admin/a2a_status?submission_id=${submissionId}`,
  );

  if (!response.ok) {
    throw new ApiError((await response.json()).error);
  }

  return await response.json();
}

export async function retrieveAdminSubmissions(
  offset: number,
  limit: number,
): Promise<{
  adminSubmissions: AdminSubmissionItem[];
  totalAdminSubmissionCount: number;
}> {
  const response = await wrappedAdminFetch(
    `${apiUrl()}/internal/admin/admin_submissions?offset=${offset}&limit=${limit}`,
  );

  if (!response.ok) {
    throw new ApiError((await response.json()).error);
  }

  return await response.json();
}

export interface FYTFormError {
  input_name: string;
  data?: string;
  text?: string;
  output_internal_id?: string;
  input_attribute?: string;
  input_namespace_uuid?: string;
}

export interface FYTFormWarning {
  input_name: string;
  data?: string;
  text?: string;
  output_internal_id?: string;
  input_attribute?: string;
  input_namespace_uuid?: string;
}

export interface FYTFormResult {
  errors: FYTFormError[];
  fyt_instance: string;
  name: string;
  warnings: FYTFormWarning[];
  output_namespace?: string;
  output_namespace_uuid?: string;
}

export interface FYTReturnError {
  code: string;
  message: string;
}

export interface FYTReturnWarning {
  code: string;
  message: string;
}

export interface FYTJurisdictionData {
  jurisdiction: string;
  refund_or_amount_owed: number;
  errors: FYTReturnError[];
  warnings: FYTReturnWarning[];
  summary_amounts: Record<string, number>;
  filing_status: string;
}

export interface FYTResponseData {
  completed_forms: FYTFormResult[];
  form_results: FYTFormResult[];
  federal_data: FYTJurisdictionData;
  state_data: FYTJurisdictionData[];
}

export interface FYTRequestResponse {
  label: string;
  description?: string;
  request_xml: string;
  response_xml: string;
  response_data?: FYTResponseData;
  error?: string;
}

export interface SubmitFYTResponse {
  client_id: string;
  state_returns: string[];
  requests: FYTRequestResponse[];
}

export interface FYTXMLResponse {
  valid: boolean;
  result: string;
  error_detail: string | null;
  xml: string | null;
}

export function submitAdminFYT(body?: {
  inputs: string;
  use_random_ssn?: boolean;
}): Promise<SubmitFYTResponse> {
  return postAdmin(
    {
      endpoint: AdminPostEndpoints.SUBMIT_FYT,
      body,
    },
    true,
  );
}

type FetchFYTXMLParams = { jurisdiction: string } & (
  | { client_id: string; return_id?: never }
  | { client_id?: never; return_id: string }
);

export function fetchFYTXML(body: FetchFYTXMLParams): Promise<FYTXMLResponse> {
  return postAdmin(
    {
      endpoint: AdminPostEndpoints.FETCH_FYT_XML,
      body,
    },
    true,
  );
}

export async function getFYTPDF(body: {
  client_id: string;
  state_returns: string[];
}): Promise<Response> {
  return await wrappedAdminFetch(
    `${apiUrl()}/internal/admin/${AdminPostEndpoints.GET_FYT_PDF}`,
    {
      method: "POST",
      body: JSON.stringify(body),
    },
  );
}

export function submitUserReviewFYT(
  returnId: string,
): Promise<SubmitFYTResponse> {
  return postAdmin(
    {
      endpoint: AdminPostEndpoints.SUBMISSION_DASHBOARD_FYT_CALC,
      body: { return_id: returnId },
    },
    true,
  );
}

export function previewFormula(body?: {
  inputs: string;
  formula: string;
  namespaceId: string;
}): Promise<{ results: Array<string>; lintErrors: Array<string> }> {
  return postAdmin({
    endpoint: AdminPostEndpoints.PREVIEW_FORMULA,
    body,
  });
}

export async function previewPdf(body?: {
  inputs?: string;
  flatten: boolean;
  returnId?: string;
}): Promise<Response> {
  return await wrappedAdminFetch(
    `${apiUrl()}/internal/admin/${AdminPostEndpoints.PREVIEW_PDF}`,
    {
      method: "POST",
      body: JSON.stringify(body),
    },
  );
}

export function fetchInputsTemplate(): Promise<{
  dataTypes: string;
}> {
  return postAdmin({
    endpoint: AdminPostEndpoints.INPUTS_TEMPLATE,
    body: null,
  });
}

export function saveFullReturnTest(body: {
  name: string;
  inputs: string;
  jurisdiction: string;
}) {
  return postAdmin({
    endpoint: AdminPostEndpoints.SAVE_FULL_RETURN_TEST,
    body,
  });
}
export function saveUnitTests(body: { tests: string }): Promise<{
  pullRequestUrl: string;
}> {
  return postAdmin({
    endpoint: AdminPostEndpoints.SAVE_UNIT_TESTS,
    body,
  });
}

export function fetchScenarios(body: { jurisdiction: string }): Promise<{
  inputFilenames: Array<string>;
  inputSchema: Record<string, unknown> | null;
}> {
  return postAdmin({
    endpoint: AdminPostEndpoints.SCENARIOS,
    body,
  }).then((res) => ({
    ...res,
    inputSchema: JSON.parse(res.inputSchema || null),
  }));
}

export type SearchScenariosRequest = {
  jurisdiction?: string;
  filter_expression?: string;
  input_queries?: Record<string, Array<string | number>>;
  computed_queries?: Record<string, string>;
  tags?: string[];
  tag_search_mode?: "any" | "all";
};

export type SearchScenariosResponse = {
  scenarios: {
    jurisdiction: string;
    name: string;
    description: string;
    tags: string[];
    query_results: Record<string, string>;
  }[];
  queries: string[];
};

export function searchScenarios(
  body: SearchScenariosRequest,
): Promise<SearchScenariosResponse> {
  return postAdmin(
    {
      endpoint: AdminPostEndpoints.SEARCH_SCENARIOS,
      body,
    },
    true,
  );
}

export function fetchScenarioJSON(body: {
  jurisdiction: string;
  name: string;
}): Promise<{
  scenarioJson: string;
}> {
  return postAdmin({
    endpoint: AdminPostEndpoints.SCENARIO_JSON,
    body,
  });
}

export type UpdateScenarioRequest = {
  jurisdiction: string;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  patch: Record<string, any>;
};

export type UpdateScenarioResponse = {
  // TODO(rwc): Define actual TypeScript types for scenario input JSON
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  scenario: Record<string, any>;
};

export function updateScenario(
  body: UpdateScenarioRequest,
): Promise<UpdateScenarioResponse> {
  return postAdmin(
    { endpoint: AdminPostEndpoints.UPDATE_SCENARIO, body },
    true,
  );
}

export type RenameOrDuplicateScenarioRequest = {
  rename_or_duplicate: "rename" | "duplicate";
  original_jurisdiction: string;
  original_name: string;
  new_jurisdiction: string;
  new_name: string;
  new_description?: string;
};

export type RenameOrDuplicateScenarioResponse = {
  // TODO(rwc): Define actual TypeScript types for scenario input JSON
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  scenario: Record<string, any>;
};

export function renameOrDuplicateScenario(
  body: RenameOrDuplicateScenarioRequest,
): Promise<RenameOrDuplicateScenarioResponse> {
  return postAdmin(
    { endpoint: AdminPostEndpoints.RENAME_OR_DUPLICATE_SCENARIO, body },
    true,
  );
}

export function cleanUpUltraTaxXML(body: {
  xml: string;
  jurisdiction: string;
}) {
  return postAdmin({
    endpoint: AdminPostEndpoints.CLEAN_ULTRA_TAX_XML,
    body,
  });
}

export function batchRowValidate(
  body: BatchRowValidationParams,
): Promise<BatchError> {
  return postAdmin({
    endpoint: AdminPostEndpoints.BATCH_ROW_VALIDATION,
    body,
  });
}

export function getXmlTemplatesForPath(body: {
  path: string;
  jurisdiction: string | null;
}): Promise<{
  xmlTemplates: Array<XmlTemplate>;
}> {
  return postAdmin({
    endpoint: AdminPostEndpoints.XSD_TEMPLATES,
    body,
  });
}

export class FormulaUpdateError extends Error {
  constructor(message: string) {
    super(message);
    this.message = message;
  }
}

export async function refundFilingFee({ userUuid }: { userUuid: string }) {
  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/submission_dashboard/refund_filing_fee/${userUuid}`,
    {
      method: "POST",
    },
  );

  if (response.status === 422) {
    throw new Error((await response.json()).error);
  } else if (!response.ok) {
    throw new Error("Unknown error");
  }

  return await response.json();
}

export async function adminSendMagicLinkEmailToUser({
  userUuid,
}: {
  userUuid: string;
}) {
  const response = await wrappedAdminFetch(
    `${supportApiUrl()}/internal/submission_dashboard/send_magic_link_email_to_user/${userUuid}`,
    {
      method: "POST",
    },
  );

  if (!response.ok) {
    throw new Error((await response.json()).error);
  }

  return await response.json();
}

function warnAdminAuth(url: string | RequestInfo): void {
  // Hopefully very temp function to help with debugging the case of the
  // enthusiastic log out mechanism
  if (isInternalUse() || isUsingPullRequestBackend()) {
    const client = readFromLocalStorage("client");
    rollbar.warn(
      `Clearing admin auth on request to url ${url} with client value: ${client?.value}`,
    );
  }
}

export async function mapPdf(file: File) {
  const payload = new FormData();
  payload.append("file", file);

  const adminAuthHeaders = getAdminAuth();

  const url = `${apiUrl()}/internal/admin/${AdminPostEndpoints.MAP_PDF}`;
  const response = await fetch(url, {
    headers: { ...FORM_DATA_HEADERS, ...adminAuthHeaders },
    method: "POST",
    body: payload,
  });

  if (response.status === 401) {
    warnAdminAuth(url);

    clearAdminAuth();
  } else {
    // Save new auth headers to cookie, which change on each request
    saveAdminAuth(response.headers);
  }
  return response.json();
}

export async function createTestUser({
  json,
  priorYearInputDataJson,
  taxFilingFeeCents,
  scheduleCPrefill,
  w2Prefill,
  priorYearAcceptedReturnPrefill,
  priorYearAcceptedReturnPrefillWithDrake,
}: {
  json?: string;
  priorYearInputDataJson?: string;
  taxFilingFeeCents?: number;
  scheduleCPrefill?: boolean;
  w2Prefill?: boolean;
  priorYearAcceptedReturnPrefill?: boolean;
  priorYearAcceptedReturnPrefillWithDrake?: boolean;
}) {
  let parsed: Record<string, unknown> = {};

  if (json) {
    parsed = JSON.parse(json);
  }

  // prior year json are inputs to simulate a prior-year user who is filing again this year
  if (priorYearInputDataJson) {
    parsed.prior_year_accepted_return_input_data = JSON.parse(
      priorYearInputDataJson,
    );
  }

  // TODO(marcia): Why isn't taxFilingFeeCents inflected?
  parsed.tax_filing_fee_cents = taxFilingFeeCents || 0;
  parsed.schedule_c_prefill = scheduleCPrefill || false;
  parsed.w2_prefill = w2Prefill || false;
  parsed.prior_year_accepted_return_prefill =
    priorYearAcceptedReturnPrefill || false;
  parsed.prior_year_accepted_return_prefill_with_drake =
    priorYearAcceptedReturnPrefillWithDrake || false;

  const response = await wrappedAdminFetch(
    `${apiUrl()}/internal/admin/test_user`,
    {
      method: "POST",
      body: JSON.stringify(parsed),
    },
  );

  return await response.json();
}

// creates a prior-year test user, but no current year return
// keeping this separate from the createTestUser function since the return shape is different
export async function createPriorYearTestUserWithoutCurrentYear({
  priorYearInputDataJson,
}: {
  priorYearInputDataJson?: string;
}) {
  const parsed: Record<string, unknown> = {};

  // prior year json are inputs to simulate a prior-year user who is filing again this year
  if (priorYearInputDataJson) {
    parsed.prior_year_accepted_return_input_data = JSON.parse(
      priorYearInputDataJson,
    );
  }

  const response = await wrappedAdminFetch(
    `${apiUrl()}/internal/admin/create_test_prior_year_user_without_current_return`,
    {
      method: "POST",
      body: JSON.stringify(parsed),
    },
  );

  return await response.json();
}

export async function getUsersWithStubbedSubmissions(
  hostBankName: string,
  state: string,
) {
  const query = new URLSearchParams({
    state: state,
    host_bank_name: hostBankName,
  });
  const response = await wrappedAdminFetch(
    `${apiUrl()}/internal/admin/test_users_with_stubbed_submitted_returns?${query}`,
    {
      method: "GET",
    },
  );

  return await response.json();
}

export async function createTestDrakeUser(json?: string) {
  const response = await wrappedAdminFetch(
    `${apiUrl()}/internal/admin/test_drake_user`,
    {
      method: "POST",
      body: json,
    },
  );

  return await response.json();
}

export async function createTestTaxSlayerUser(json?: string) {
  const response = await wrappedAdminFetch(
    `${apiUrl()}/internal/admin/test_tax_slayer_user`,
    {
      method: "POST",
      body: json,
    },
  );

  return await response.json();
}

export async function getReviewApps() {
  const response = await wrappedAdminFetch(
    `${apiUrl()}/internal/admin/review_apps`,
    {
      method: "GET",
    },
  );

  return await response.json();
}

export async function getPullRequests() {
  const response = await wrappedAdminFetch(
    `${apiUrl()}/internal/admin/pull_requests`,
    {
      method: "GET",
    },
  );

  return await response.json();
}

export async function checkoutBranch({ branchName }: { branchName: string }) {
  return postAdmin({
    endpoint: AdminPostEndpoints.CHECKOUT_BRANCH,
    body: {
      branchName,
    },
  });
}

export async function getPRStatus() {
  const response = await wrappedAdminFetch(
    `${apiUrl()}/internal/admin/pr_status`,
    {
      method: "GET",
    },
  );

  return await response.json();
}

export function signOutAdmin() {
  clearLocalStorage();

  return wrappedAdminFetch(`${apiUrl()}/admin_auth/sign_out`, {
    method: "DELETE",
  });
}

export async function getStep(fieldKey: string) {
  // If the user tries to edit their income, we do not circle back to the Atomic
  // SDK. Instead, we go back to the INCOME_VERIFICATION screen.
  if (fieldKey === "w2_income") {
    return fetchStep({
      target_step_id: StepType.INCOME_VERIFICATION,
    });
  } else {
    return fetchStep({
      target_step_id: StepType.QUESTION,
      target_question_id: fieldKey,
    });
  }
}

// TODO(nihar): remove me if this is used for TRU only
export async function fetchStep(targetStep?: {
  target_step_id: string;
  target_question_id?: string;
}) {
  // Given our current architecture, we'll only call fetchStep after
  // we've verified that the user has a valid token
  // NOTE(nihar): token may actually be null, but this is no longer used?
  const token = getUserTokenIfExists();
  const query = targetStep
    ? `${new URLSearchParams(targetStep).toString()}`
    : "";

  const res = await fetch(`${apiUrl()}/internal/steps?${query}`, {
    headers: { ...BASE_HEADERS, Authorization: `Bearer ${token}` },
  });

  if (!res.ok) {
    throw new Error();
  }

  const nextStep = await res.json();

  return { nextStep };
}

// TODO(nihar): remove me? (is this used for TRU only?)
async function putStep(body: string) {
  // Given our current architecture, we'll only call putStep after
  // we've verified that the user has a valid token
  // NOTE(nihar): token may actually be null, but this is no longer used?
  const token = getUserTokenIfExists();
  const res = await fetch(`${apiUrl()}/internal/steps`, {
    headers: { ...BASE_HEADERS, Authorization: `Bearer ${token}` },
    method: "PUT",
    body,
  });

  if (!res.ok) {
    throw new Error();
  }

  return await res.json();
}

let userAllowsPolling = false;
export async function quitPolling(currentStep: Step) {
  // Mark that the user wants to quit polling
  userAllowsPolling = false;

  // Immediately put the current step to get the next (manual fallback) step
  return await putStep(JSON.stringify({ ...currentStep }));
}

async function pollForNextStep(
  currentStep: Step,
  // Retry 14 times at a 5 second delay between each poll, resulting in 70 maximum seconds.
  // TODO: Potentially add a button to initiate the timeout after a certain amount of time,
  // e.g. "This is taking a long time, want to enter your payroll data manually?"
  // This value should be the number of seconds passed to Timer in LoadingScreen plus 3
  // (for the initial polling seconds in LoadingScreen).
  maxTries = 14,
) {
  userAllowsPolling = true;

  let numTries = 0;
  let { nextStep } = await fetchStep();

  // Keep fetching while we're still on the same step, we still
  // have more tries left, and the user is still tolerating the delay
  while (
    nextStep.id === currentStep.id &&
    numTries < maxTries &&
    userAllowsPolling
  ) {
    numTries++;
    await delay(5000);

    ({ nextStep } = await fetchStep());
  }

  if (!userAllowsPolling) {
    // Note: keep this branch first to avoid race conditions or any
    // possible screen flickering. If the user quit polling, we will
    // use the response from quitPolling's putStep rather than any
    // fetchStep during this pollForNext.
    return {};
  } else if (nextStep.id !== currentStep.id) {
    // We retrieved the user's next step from polling
    return { nextStep };
  } else {
    // We've decided to time out after ~ 1 min
    return await putStep(JSON.stringify({ ...currentStep }));
  }
}

export async function getNextStep(currentStep: Step) {
  switch (currentStep.id) {
    case StepType.DATA_LOADING:
      return await pollForNextStep(currentStep);
    // TODO(marcia): Consider how useful it is to enumerate all the
    // remaining steps (vs defaulting to putStep)
    case StepType.QUESTION:
    case StepType.INITIAL_SPLASH:
    case StepType.INCOME_VERIFICATION:
    case StepType.SUMMARY:
    case StepType.PAYROLL_CHOICE:
    case StepType.PAYROLL_PAY_CYCLE:
    case StepType.PAYROLL_GROSS_AMOUNT:
    case StepType.PAYROLL_FEDERAL_INCOME_TAX:
    case StepType.PAYROLL_NET_PAY:
    case StepType.PAYROLL_CONNECT:
    case StepType.PAYROLL_CONNECT_PENDING:
    case StepType.OUTCOME:
    case StepType.FINDINGS_AUTOMATED:
    case StepType.FINDINGS_MANUAL:
    case StepType.SIGNATURE:
    case StepType.EMPLOYER_INFORMATION:
    case StepType.UPDATE_OWN_W4_INSTRUCTIONS: {
      return await putStep(JSON.stringify({ ...currentStep }));
    }
    default:
      return { nextStep: { id: StepType.ERROR } };
  }
}

// Check if we're inside an iOS WKWebView, ReactNativeWebView, Android WebView, or
// iframe.
// TODO: make sure this works for Flutter too.
export function isEmbedded(): boolean {
  return Boolean(
    window.ReactNativeWebView ||
      window.webkit ||
      window.Android ||
      window.self !== window.top,
  );
}

// Represents the possible event types we might send via the Webview
type WebviewEvent =
  | {
      name: SDKEvent;
    }
  | {
      name: SDKEvent;
      userEvent: UserEvent;
    };

function sendWebviewEvent(webviewEvent: WebviewEvent): void {
  const eventJsonString = JSON.stringify(webviewEvent);

  // Wrap all of the postMessage calls in a try-catch so we can warn instead of getting rollbar errors if a
  // partner messes up their implementation
  try {
    if (window.ReactNativeWebView) {
      // TODO(marcia): Improve device detection -- when using expo to emulate
      // ios with the react native app, this control flow is not entered. We
      // fall through to the following webkit branch, where it seems to work..?
      window.ReactNativeWebView.postMessage(eventJsonString);
    } else if (window.Android) {
      // NOTE: the name of the Javascript Interface ("Android") and the method
      // ("postMessage") are user-defined, see the column-android-sample repo.
      window.Android.postMessage(eventJsonString);
    } else if (window.parent) {
      // Usually when sending postMessage, it is better to specify a particular
      // targetOrigin than the wildcard "*". But, we cannot access
      // window.parent.location.origin without triggering a cross-origin security
      // error. While we are are only sending "SDKEvent.COLUMN_ON_CLOSE", it seems
      // OK to send a close event to anyone who is listening
      // If we were to send more sensitive info, then let's re-think this -- would
      // ColumnTax.openModule(config) need to specify a target origin in config?
      window.parent.postMessage(eventJsonString, "*");
    }

    // The "window.parent" clause gets hit in iframe based implementations.
    // This clause gets hit in Native iOS apps.
    //
    // "window.parent.postMessage" is a no-op in iOS
    // "window.webkit.messageHandlers" is a no-op in an iFrame
    //
    // Here are the two scenarios:
    // 1. iFrame implementation.
    //    a. The "window.parent" condition above should fire
    //    b. We will also enter into this clause here. Since the messageHandlers aren't defined (and likely
    //       cannot be), this is a no-op.
    //
    // 2. iOS app:
    //   a. "window.parent.postMessage" will fire without doing anything
    //   b. Since iOS *does* have the message handlers for our SDK events, we will post them successfully here
    if (window.webkit && window.webkit.messageHandlers) {
      const sdkEventName = webviewEvent.name;

      // Only postMessage if ios host app has registered to listen for this event
      // This also should protect against sending these messages in Propel's app
      window.webkit.messageHandlers[sdkEventName]?.postMessage(eventJsonString);
    }
  } catch (error) {
    rollbar.warn(
      `webviewEvent with payload ${JSON.stringify(
        webviewEvent,
      )} failed to post`,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error as any,
    );
  }
}

export async function onUserEvent(userEvent: UserEvent) {
  const eventData = {
    name: SDKEvent.COLUMN_ON_USER_EVENT,
    userEvent,
  };

  sendWebviewEvent(eventData);
}

export async function onNavigate(url: string) {
  if (isEmbedded()) {
    onUserEvent({
      name: "navigate",
      metadata: {
        timestamp: new Date().toISOString(),
        url: url,
      },
    });
  } else {
    return;
  }
}

// Under certain conditions, we may want to automatically
// redirect the user to an exit page.
export function redirectToExitPage() {
  window.location.replace("/exit");
}

// DO NOT CALL THE FUNCTION DIRECTLY FOR DIY!! Please use exitDiy in diy/api.ts
export async function onExit() {
  // If we're not inside an iOS WKWebView, ReactNativeWebView, Android WebView,
  // or iframe do nothing.
  if (isEmbedded()) {
    const eventData = { name: SDKEvent.COLUMN_ON_CLOSE };
    sendWebviewEvent(eventData);
  } else {
    redirectToExitPage();
  }
}
