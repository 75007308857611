import React, { useEffect, useRef, useState } from "react";

import { sendEventDrakeHandoff } from "../api";
import { ScreenId } from "../types";

import { onNavigate } from "../../utils/api";
import { delay } from "../../utils/general";
import { SAMLPayloadSuccess, samlAuthenticate } from "../../utils/saml";
import LoadingBlock from "./LoadingBlock";

interface Props {
  // TODO(marcia): One day fix this typo -- milliseconds
  timeoutMilleseconds: number;
  errorScreenId: ScreenId;
  samlUrl: string;
  onSubmit: (screenId: ScreenId) => void;
}

// Used to perform SAML based auth and connect to our fallback
function FallbackBlock({
  timeoutMilleseconds,
  errorScreenId,
  onSubmit,
  samlUrl,
}: Props) {
  const [SAMLState, setSAMLState] = useState<null | SAMLPayloadSuccess>(null);

  const formRef = useRef<null | HTMLFormElement>(null);
  const renderTime = useState(performance.now())[0];

  const timedNavigate = async () => {
    await delay(timeoutMilleseconds);
    onSubmit(errorScreenId);
  };

  useEffect(() => {
    // Navigate to error screen if this takes longer than the configured timeout threshold
    timedNavigate();

    const fetchSamlResponse = () => {
      samlAuthenticate(samlUrl)
        .then((response) => {
          switch (response.status) {
            case "pending": {
              return;
            }
            case "success": {
              setSAMLState(response);
              return;
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };

    // Try getting the SAML response immediately in case it's already available.
    // This happens in the case of returning users.
    fetchSamlResponse();

    // Poll since we're creating a user with our fallback which might take a
    // few seconds
    // TODO(Billy): Consider a maxmium number of polling steps here and
    // implement some sort of error handling
    const interval = setInterval(() => {
      // Only keep trying until we've set the form state successfully
      if (formRef.current === null) {
        fetchSamlResponse();
      }
    }, 3 * 1000);

    return () => clearInterval(interval);
  }, []);

  // Once the SAMLState loads, automatically submit the form to perform the redirect
  useEffect(() => {
    if (formRef.current) {
      const pollDurationSeconds = (performance.now() - renderTime) / 1000.0;
      sendEventDrakeHandoff({
        pollDurationSeconds,
        callback: () => formRef.current?.submit(),
      });
    }
  }, [SAMLState]);

  if (SAMLState === null) {
    return <LoadingBlock text="Loading Column Tax Classic..." />;
  } else {
    onNavigate(SAMLState.acsUrl);

    return (
      <>
        <LoadingBlock />
        <form
          ref={formRef}
          action={SAMLState.acsUrl}
          acceptCharset="UTF-8"
          method="post"
        >
          <input
            type="hidden"
            name="SAMLResponse"
            id="SAMLResponse"
            value={SAMLState.samlResponse}
          />
        </form>
      </>
    );
  }
}

export default FallbackBlock;
